.categoryContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
}
.category {
	display: flex;
	flex-direction: row;
	width: calc(50% - 32px);
	background: #e39961;
	font-size: 18px;
	font-weight: 600;
	margin: 8px;
	justify-content: center;
	align-content: center;
	color: rgba(255,255,255,0.9);
	height: 88px;
	overflow: hidden;
	border-radius: 4px;
	box-shadow: 0 1px 3px rgba(124,88,210,0.12), 0 1px 2px rgba(124,88,210,0.24);
}
.category:hover {
	cursor: pointer;
}
.category div:first-child {
	text-align: left;
	padding:32px;
	width: 65%;

}
.category div:nth-child(2) {
	background: #fff;
	padding:32px;
	border-top-left-radius: 128px;
	border-bottom-left-radius: 128px;
	height: 128px;
	flex-grow: 1;
	background-size: cover;
	background-origin: revert;
	background-repeat: no-repeat;
}

.container {
	text-align: center;
}
.indexArticle {
	margin: 0 auto;
	text-align: justify;
    padding: 32px;
	background: #ffe6d2;
	border-radius: 8px;
}
.showExampleLink {
	font-weight: 600;
	text-align: left;
}
@media only screen and (max-width: 600px) {
	.categoryContainer {
		text-align: center;
	}
	.category {
		width:100%;
	}
	.indexArticle {
		padding: 16px;
		margin: 8px; 
		margin-top: 16px;
		text-align: left;
	}
	.showExampleLink {
		font-weight: 600;
		text-align: left;
	}	
	.topImage {
		display: none;
	}
}

@media only screen and (min-width: 600px) and (max-width: 1200px) {
	.indexArticle {
		margin: 8px;
		border-radius: 8px;
	}
}