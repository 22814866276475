.container {
	background: #ffe6d2;
	border-radius: 4px;
	display: inline-block;
	vertical-align: top;
	width: 224px;
	position: relative;
	margin: 8px;
	padding-bottom: 4px;
	margin-bottom: 16px;
	text-align: center;
	box-shadow: 0 1px 3px rgba(124,88,210,0.12), 0 1px 2px rgba(124,88,210,0.24);
	overflow: hidden;
	transition: all 0.3s cubic-bezier(.25,.8,.25,1);


}
.container:hover {
	box-shadow: 0 8px 18px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22);
}
.headerContainer {
	font-size:15px; 
	background: #e39961; 
	color: white; 
	border-radius: 0 0 0 0; 
	margin: 0;
	height: 46px;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 42px;
	padding-left: 4px;
	padding-right: 4px;
	font-weight: 400;
	white-space: nowrap;
}
.image {
	border-radius: 132px;
	margin-top: 8px;
	margin-bottom: 8px;
	height: 132px;
	background: #ffe6d2;
}

.readMoreBtn {
	background: #e39961;
	border: 1px solid #e39961;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	padding: 8px 16px 8px 16px;
	border-radius: 2px;
	margin-top: 8px;
	cursor: pointer;
	text-decoration: none;
	box-shadow: 0 1px 3px rgba(124,88,210,0.12), 0 1px 2px rgba(124,88,210,0.24);
}
.stars {
	font-weight: 800;
	font-size: 18px;
	color: #e39961
}
.affiliateTitle {
	font-weight: 500;
	font-size: 12px;
}
@media only screen and (max-width: 600px) {
    .container {
		margin:2px;
		width: calc(50% - 8px);
		background: #ffe6d2;
		border-radius: 0;
		border: 1px solid #f8ac6f;
		box-shadow: none;
	}
	.headerContainer {
		background: #e39961;
		color: #fff;
		font-weight: 500;
		max-height: 52px;
		font-size: 14px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin-bottom: 4px;
	}
	.image {
		height: 128px;
	}
	.readMoreBtn {
		border-radius: 0;
	}
}


@media only screen and (min-width: 600px) and (max-width: 1200px) {
    .container {
		margin:8px;
		width: calc(33% - 16px);
		background: #ffe6d2;
		border-radius: 0;
		border: 1px solid #f8ac6f;
		box-shadow: none;
		border-radius: 4px;
	}
	.headerContainer {
		font-size:14px; 
		background: #e39961; 
		color: white; 
		border-radius: 0 0 0 0; 
		margin: 0;
		height: 46px;
		overflow: hidden;
		text-overflow: ellipsis;
		font-weight: 500;
	}
	.image {
		height: 128px;
	}
	.readMoreBtn {
		border-radius: 0;
	}
}